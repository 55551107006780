 
@font-face {
    font-family: 'Din'; 
    src:  url('../fonts/din-regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
}
 
@font-face {
    font-family: 'Din-bold'; 
    src:  url('../fonts/din-bold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
}

@font-face {
    font-family: 'Gotham-light'; 
    src: 
        url('../fonts/Gotham-Cyrillic/GothamPro-Light.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
}

@font-face {
    font-family: 'Clinton';
    src: 
        url('../fonts/Clinton/Clinton semibold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
}
 
@font-face {
    font-family: 'MyriadPro';
    src: 
        url('../fonts/MyriadPro.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
}
 
// @font-face {
//     font-family: 'MyriadPro';
//     src: 
//         url('../fonts/MyriadPro-Bold.ttf') format('truetype');
//         font-weight: normal;
//         font-style: normal;
// }
@font-face {
    font-family: 'LeagueGothic';
    src: local('League Gothic'), local('league-Gothic'),  url('../fonts/LeagueGothic.ttf') format('truetype'),  url('../fonts/LeagueGothic.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}