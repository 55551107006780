@import "functions";
@import "variables";

// Базовая ф-ция конвертации в vw
// ex .element { @include vw($font-size: 20, $margin: 10 auto)  }
@mixin vw($args...) {
	//@each $property, $value in keywords($args) { #{$property}:  map-get(generateVw($value), "px");}
	@include vw-responsive(keywords($args));
}

@mixin vw-responsive($attributes) {
	@each $property, $value in $attributes {#{$property}:  map-get(generateVw($value, 1), 'vw');}
	@each $width, $multi  in $breakpoints {
		@include respond-to($width) {
			@each $property, $value in $attributes {
				#{$property}:  map-get(generateVw($value, $multi), 'vw');
			}
		}
	}
	
	//Save pixels for mobile devices
	@include respond-to(300px) {
		@each $property, $value in $attributes {
			#{$property}:  map-get(generateVw($value, 1), 'px');
		}
	}
}

// ex .element{ @include respond-to(small) { ... } }
@mixin respond-to($breakpoint) {
	@if $breakpoint == "small" {
		@media (max-width: 769px) {
			@content;
		}
	}
	@else if $breakpoint == "xs" {
		@media (max-width: 500px) {
			@content;
		}
	}
	@else if $breakpoint == "xxs" {
		@media (max-width: 430px) {
			@content;
		}
	}

	@else {
		@media (max-width: #{$breakpoint}px) {
			@content;
		}
	}
}

@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&:-ms-input-placeholder      {@content}  
}

@mixin flex(){
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin transition(){
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

// Generic transform
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
// Scale
@mixin scale($scale) {
	 @include transform(scale($scale));
}
