@import "system/variables";

/* Global */
html,
body { 

    @include respond-to(small) {

        // min-width: 768px;
    }
}

html {
    position: relative;
}

body {
 

    // @include respond-to(small) {
    //     min-width: 768px;
    //     margin: 0 auto;
    // }

 
}

 
a {
    font-size: inherit;
    font-weight: inherit;
}

img {
    max-width: 100%;
}

sup {
    font-size: 60%;
}

.site-width {
    @include vw($max-width: 1220, $padding: 0 15);
    width: 100%;
    margin: 0 auto;

    @include respond-to(small) {
        padding: 0 15px;
        // max-width: 610px;
        max-width: 700px;
    }
}

.is-hidden {
    display: none;
}

.is-disabled {
    opacity: 0.7;
    cursor: not-allowed !important;
}

.padding {
    @include vw($padding: 100 0);

    &--top {
        @include vw($padding-top: 100);
    }

    &--bottom {
        @include vw($padding-bottom: 100);
    }
}

.color-white {
    color: #fff;
}

.color-brand {
    color: $brand;
}

.color-dark {
    color: #5C5C5C;
}

.header {
    @include vw($padding: 20 0);
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9999;
    ;
    &.is-hide {
        transform: translateY(-100%);
        // top: -100%;
        // overflow: hidden;
        position: fixed;
        @include vw($padding: 10 0);
        @include respond-to(small){
            padding: 10px 0;
            // transform: translateY(0%);
        }
        .header__burg{
            top: 16px;
        }
    }

    &.is-fixed {
        position: fixed;
        transform: translateY(0%);
        background: $brand;

        // background: rgba(255,255,255,0.5);
        @include respond-to(small) {
            .header__btn {
                display: block;
            }

            .header__logo {
                display: none;
            }
        }

    }
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        @include vw($flex-basis: 35);
@include respond-to(small){
    flex-basis: 105px;
}
        img {
            width: 100%;
        }
    }
    &__btn {
        display: none;

        cursor: pointer;
        @include vw($padding: 15 30,
            $border-radius: 25,
            $min-width: 200,
            $font-size: 20);
        font-family: $ff;
        color: #000;
        // color: #fff;
        font-weight: bold;
        border: none;
        background: $brand;
        box-shadow: 0 0px 20px 0 rgba(5, 16, 44, .27);
        transition: .4s;
        text-align: center;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            box-shadow: 0 5px 30px 0 rgba(5, 16, 44, .20);
            transform: translateY(-3px) scale(1.01);

        }
    }
    &__burg {
        position: absolute;
        top: 25px;
        right: 40px;
        width: 60px;
        z-index: 9;
        cursor: pointer;
        display: none;

        @include respond-to(small) {
            display: block;
            top: 45px;
            right: 40px;
            width: 90px;

        }

        span {
            display: block;
            height: 3px;
            margin-bottom: 8px;
            background: #000;
            @include respond-to(small){
                height: 7px;
                margin-bottom: 11px;
            }
        }
    }

}

.menu {
    display: flex;
z-index: 999;
position: relative;
    @include respond-to(small) {
        width: 100%;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        background: #000;
        padding: 40px;
        display: flex;
        z-index: 99;
        flex-wrap: wrap;
        transform: translateX(100%);
        transition: all 0.6s ease;
        height: 100vh;
    }

    &.is-open {
        @include respond-to(small) {
            transform: translateX(0%);
            overflow: auto;
        }
    }

    &__item {
        @include vw($font-size: 12,
            $padding: 10 15);
        font-family: $ff;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.4s ease;
        position: relative;
        z-index: 9;

        @include respond-to(small) {
            width: 100%;
            display: block;
            font-size: 40px;
            color: #fff;
            text-align: center;
        }

        &--close {
            display: none;

            @include respond-to(small) {
                display: block;
                text-align: right;
                font-size: 20px;
                margin-bottom: 40px;
            }
        }

        &:hover {
            opacity: 0.8;
            transform: translateY(-7px);
            text-decoration: none;
        }

        &--border {
            @include vw($border: 1 solid #000,
                $border-radius: 5);

            &:hover {
                opacity: 1;
                color: #fff;
                background: #000;
                text-decoration: none;
            }
        }
    }
}

.btn {
    cursor: pointer;
    display: inline-block;
    @include vw($padding: 15 30,
        $border-radius: 25,
        $min-width: 280,
        $font-size: 30);
    font-family: $ff;
    color: #000;
    // color: #fff;
    font-weight: bold;
    border: none;
    background: $brand;
    box-shadow: 0 0px 20px 0 rgba(5, 16, 44, .27);
    transition: .4s;

    &:hover,
    &:active,
    &:focus {
        box-shadow: 0 5px 30px 0 rgba(5, 16, 44, .20);
        transform: translateY(-3px) scale(1.01);

    }

    &--black {
        background: #000;
        color: #fff;

        &:hover,
        &:active,
        &:focus {
            color: #fff;
        }
    }

    &--white {
        background: #fff;
        color: #000;

        &:hover,
        &:active,
        &:focus {
            background: #000;
            color: #fff;
        }
    }

    &--hide-desktop {
        display: none;
    }
}

.arrows {
    @include vw($font-size: 68,
        $width: 40);
    display: flex;
    flex-wrap: wrap;

    i {
        // opacity: 0;
        width: 100%;
        line-height: 0.5;
        @include vw($height: 30);
        animation: animation-fade 3s ease infinite;

        &:nth-child(2) {
            animation-delay: 0.5s;
        }

        &:nth-child(3) {
            animation-delay: 1s;
        }
    }

}

.block {
    display: flex;
    &__title {

        @include vw($flex-basis: 320,
            $height: 500);
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;

        @include respond-to(1024) {
            @include vw($flex-basis: 250);
        }

        @include respond-to(small) {
            flex-basis: 150px;
        }

        .title__text {
            transform: rotate(-90deg);
            @include vw($font-size: 48);
            text-align: right;
            font-family: $ff-din-bold;
            position: absolute;
            top: 45%;
            left: -27%;
            width: 100%;

            @include respond-to(1024) {
                white-space: nowrap;
                left: -8%;
                top: 45%;
            }

            @include respond-to(small) {
                top: 55%;
            }

            &--white {
                color: #fff;
            }
        }
        &.r-top {
            .title__text {
                @include respond-to(small) {
                    top: 44%;
                }
            }
        }
    }

    

    &__text {
        font-family: $ff-din-bold;
        @include vw($font-size: 13,
            $margin-bottom: 10);
        line-height: 1.3;

        @include respond-to(small) {
            font-size: 11px;
            margin-bottom: 15px;

        }
    }

    &__content {
        display: flex;
        flex-grow: 1;
    }
}

.title {
    font-family: $ff-din-bold;
    @include vw($font-size: 48);

    &__circle {
        @include vw($width: 70,
            $height: 70,
            $top: 30,
            $left: 22);
        position: absolute;
        background: #fff;
        // opacity: 0.7;
        display: block;
        border-radius: 50%;

        &--grey {
            background: rgb(191, 191, 191);
        }
    }

    &--sm {
        font-family: $ff-din-bold;
        @include vw($font-size: 20,
            $margin-bottom: 10);

    }

    &--white {
        color: #fff;
    }

    &--yellow {
        color: $brand;
    }
}

.suppl {
    color: #fff;

    &__header {
        color: #fff;

        p {
            font-family: $ff-got;
            color: #fff;
            @include vw($font-size: 14);

            @include respond-to(small) {
                font-size: 12px;
            }
        }
    }

    &__row {
        display: flex;
        color: #fff;
        align-content: center;
        align-items: flex-end;
        justify-content: space-between;
        @include vw($margin-bottom: 15);
    }

    &__col {
        flex-grow: 0;
        flex-shrink: 0;

        &--first {
            flex-basis: 69%;

        }

        &--sec {
            flex-basis: 24%;
        }

        &--third {
            flex-basis: 7%;
        }
    }

    &__amount {
        @include vw($border-top: 1 solid #fff,
            $border-bottom: 1 solid #fff,
            $font-size: 9,
            $line-height: 0,
            $padding: 9 0 3 0);
    }

    &__ingr {
        &--name {
            color: #fff;
            line-height: 1;
            font-family: $ff-din-bold;
            @include vw($font-size: 14,
                $padding: 8 0 3 0);

            span {
                font-family: $ff-got;
                @include vw($font-size: 9);
            }
        }

        &--line {
            width: 90%;
            background: rgba(255, 255, 255, 0.2);
            @include vw($border-radius: 20,
                $height: 15);

            &:before {
                content: '';
                display: block;
                width: 80%;
                background: $brand;
                @include vw($border-radius: 20,
                    $height: 15);
            }

            &-sec {
                &:before {
                    width: 70%;
                }
            }

            &-third {
                &:before {
                    width: 15%;
                }
            }
        }
    }

    &__qwnt {
        color: #fff;
        line-height: 0;
        font-family: $ff-din-bold;
        @include vw($font-size: 14,

            $padding: 8 0 3 0);
    }

    &__footer {
        font-family: $ff-got;
        @include vw($font-size: 9);
        color: #fff;
        width: 80%;
        line-height: 1.4;
    }
}

.footer {
    text-align: center;
    box-shadow: 0 -9px 15px rgba(5, 16, 44, .13);

    @include respond-to(small) {
        padding: 60px 0;
    }

    p {
        width: 70%;
        @include vw($font-size: 14,
            $margin:0 auto 20);
        font-family: $ff;
        font-weight: bold;
        line-height: 1.8;

        @include respond-to(small) {
            width: 100%;
        }
    }

}

.b-modal.b-modal--suppl {
    top: 0;
    max-height: 100%;
}

.b-modal.b-modal--suppl .modal-dialog {
    border-radius: 6px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
    padding: 0px;
}