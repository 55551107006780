@import "system/variables";
/* Global */
html{
    position: relative;
}
body {
    // font-size: 18px;
    // font-family: $ff;
    // color: $text;
    // background: #fff;
    // line-height: 1.4;
}
p {
    // font-size: 18px;
    // line-height: 1.4;
}

a {
    font-size: inherit;
    font-weight: inherit;
}

img {
    max-width: 100%;
}
sup{
    font-size: 60%;
}
.p-b{
    padding-bottom: 40px;
}
.header{
    &__top{
        position: relative;
        .m-auto{
            height: 100%;
            position: static;
        }
        .tt-box-info{
            height: 100%;
            li { 
                display: flex;
                align-items: center;
                &:last-child{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 20px;
                }
                .social{
                    height: 100%;
                    padding: 3px 0;
                    margin-top: 0;
                    a{
                        padding: 5px 0;
                        height: 100%;
                        margin: 0;
                        img{
                            width: auto;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}
.g-contact {
    form{
        width: 96%;
        margin: 0 auto;
        max-width: 700px;
    }
    .help-block{
        font-size: 16px;
    }
    .form-group {
        margin-bottom: 10px;
    }
    
    .form-control {
        height: 40px;
    }      
    textarea.form-control {
        min-height: 100px;
    }  
    &_wrap {
        width: 600px;
        max-width: 100%;
        border: 1px solid $brand;
        margin: 0 auto;
        padding: 40px 5% 20px;
    }
    &_header {
        color: $brand;
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 40px;
    }
    &_btn {
        text-align: center;
        margin: 30px auto 10px;
        button {
            background: $brand;
            color: #fff;
            &:hover {
                color: #fff;
            }
        }
    }
    &_footer{
        text-align: center;
        font-size: 16px;
    }
    &_loader{
        width: 20px;
        height: 20px;
        margin: 10px auto;
        display: none;
        img{
            width: 100%;
        }
    }
}
.social{
    margin-top: 10px; 
    a{
        margin-right: 10px;
        display: inline-block;
        &:hover{
            opacity: 0.7;
        }
    }
}

.footer{
    &__grid{
        display: flex;
        justify-content: space-between;
        @include respond-to(1022){
            flex-wrap: wrap;
        }
    }
    &__col{
        flex-basis: 32%;
        flex-grow: 0;
        flex-shrink: 0;
        overflow: hidden;
        .tt-item{
            @include respond-to(768){
            // @include respond-to(600){
                padding-left: 0 !important;
                
            }
         }
        &:nth-child(2){
            @include respond-to(1022){
            flex-basis: 100%;
            order: 1;
            padding: 0px 0;
            text-align: center;
        }
        }
        &:nth-child(3){
            @include respond-to(1022){
                flex-basis: 30%;
                order: 2;
                padding: 20px 0;
        }
            // @include respond-to(600){
            @include respond-to(768){
                flex-basis: 100%;
                order: 2;
                padding: 10px 0;
        }
        }
       
        &-grid{
            display: flex;
            justify-content: flex-start;
            @include respond-to(1022){
                flex-basis: 70%;
                order: 3;
                padding: 20px 0;

            }
            // @include respond-to(600){
            @include respond-to(768){
                flex-basis: 100%;
                order: 3;
                padding: 10px 0;
                flex-wrap: wrap;
        }
        }
    }
    
    &__map{
        flex-basis: 50%;
        position: relative;
        padding-bottom: 23%; 
        flex-grow: 0;
        flex-shrink: 0;
        // @include respond-to(600){
        @include respond-to(768){
            flex-basis: 100%;
            order: 2;
            padding: 20px 0 50%;
    }
        iframe{
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
    }
    &__address{
        flex-basis: 48%; 
        flex-grow: 0;
        flex-shrink: 0;
        @include respond-to(768){
        // @include respond-to(600){
            flex-basis: 100%;
            order: 1;
            padding: 10px 0;
    }
    }
}

.modal-header{
border-bottom: none;
padding-bottom:5px;
}
.modal-header .close {
    margin-top: -2px;
    position: absolute;
    right: 10px;
    top: 10px;
}
.modal-title{
    font-size: 26px;
    font-size: 26px;
    font-weight: bold;
}