@import "system/variables"; 
@import "system/mixin"; 

.muscle__order {
    overflow: hidden;
    background: rgb(228, 228, 228); 
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%; 
    transform: translateX(-0%); 
    transition: all 0.7s ease;
&.is-hide{
    transform: translateX(100%); 
}
    @include respond-to(small) {
        height: 100vh;
        // min-height: 650px;
    }

    .order {
        &__inner{
            @include vw($min-height: 750);
            overflow: scroll;
            @include vw($padding: 72 0); 
            // @include respond-to(small){
            //     min-height: 650px;
            // }
        }
        &__step{ 
            overflow: scroll;
            height: 100vh;  
            background: #fff;
            position: absolute;
            width: 100%;
        }
        &__title {
            font-family: $ff-got;
            text-transform: uppercase;
            @include vw($font-size: 32,
                $margin-bottom: 0,
                $margin-top: 0);
            line-height: 1.4;
            text-align: center;

            // @include respond-to(small) {
            //     font-size: 31px;
            //     margin-top: 0px;
            //     // margin-bottom: 200px;
            //     padding-right: 0;
            // }

            h2 {
                font-weight: bold;
            }
        }

        &__btn {
            // text-align: center;
            // @include vw($border: 2 solid #868686,
            //     $border-radius: 8,
            //     $font-size: 18,
            //     $margin-bottom: 0,
            //     $margin-top: 0);
            // line-height: 1.4;


            a,
            &>span {
                text-align: center;
                @include vw($border: 2 solid #868686,
                    $border-radius: 8,
                    $font-size: 18,
                    $margin-bottom: 0,
                    $margin-top: 0);
                line-height: 1.4;


                font-family: $ff-clinton;
                font-weight: bold;
                text-transform: uppercase;
                text-decoration: none;
                color: rgb(128, 128, 128);
                display: block;
                @include vw($padding: 25 30 20,
                    $border-radius: 8);
                box-shadow: 0 2px 10px 0 rgba(5, 16, 44, .27);
                background: #fff;

                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                }
            }

            a {
                &:hover {
                    box-shadow: 0 4px 10px 0 rgba(5, 16, 44, .27);
                }
            }

            &--sm {
                text-align: center;

                @include vw($margin: 15 auto);

                a,
                span {
                    display: inline-block;
                    text-transform: lowercase;
                    @include vw($font-size: 13,
                        $padding: 12 5);
                }
            }
        }


        &__close {
            font-family: $ff;
            position: absolute;
            font-weight: bold;
            cursor: pointer;
            @include vw($top: 40,
                $left: 40,
                $font-size: 40);
        }


        &__layout {
            display: flex;
            justify-content: flex-end;

            &--center {
                flex-grow: 0;
                @include vw($flex-basis: 350); 
                @include respond-to(small){
                    @include vw($flex-basis: 768);  
                   flex-basis: 100%;
                } 
                &.r-center{
                    transform: translateX(230px);
                    transform: translateX(40%);
                    @include respond-to(400){
                        transform: translateX(40%);
                    }
                }
            }

            &--right {
                flex-grow: 0;
                overflow: hidden;
                @include vw($flex-basis: 530);
            }

            &--imgs {
                position: relative; 
                @include vw( 
                    $height: 173,
                    $width: 90,
                    // $margin: 150 auto 5,
                    $margin: 104 auto 5
                    );

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
            }

            &--name {
                border: none;
                @include vw($border: none !important);
                text-align: center;

                &>span { 
                    text-align: center;
                    background: #fff;
                    display: inline-block;
                    @include vw($border: 2 solid #868686,
                        $border-radius: 8,
                        $font-size: 18,
                        $margin-bottom: 0,
                        $margin-top: 0);
                    line-height: 1.4;
                }
            }

            &--btns {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                @include vw($margin-top: 17,
                $padding: 0 60);
                // @include respond-to(small){
                //     padding: 0 40px;
                // }
                .order__btn {
                    @include vw($margin: 0 10 10,
                    $width: 75);

                    a.active {
                        background: $brand;
                    }

                    a.is-disable { 
                            cursor: not-allowed; 
                    } 
                }
            }

            &--title {
                font-family: $ff-din-bold; 
                @include vw($margin-top: 93,
                    $font-size: 24,
                    $margin-bottom: 11);
                width: 65%;
                line-height: 1.5;
                font-weight: bold;
            }

            &--descr {
                font-family: "Clinton", sans-serif;
                line-height: 1.6;
                width: 70%;
                @include vw($padding-right: 15,
                    $font-size: 15,
                    $margin-bottom: 36);
            }

            &--price {
                font-family: "MyriadPro", sans-serif;
                @include vw($font-size: 31,
                    $margin-bottom: 9);
            }

            &--checkout {
                border: none;
                text-align: left;
                @include vw($margin-bottom: 20,
                    $border: none,
                    $border-radius: 0);

                a {
                    display: inline-block;
                    text-align: center;
                    @include vw($border: 2 solid #868686,
                        $border-radius: 8,
                        $font-size: 18,
                        $margin-bottom: 0,
                        $margin-top: 0);

                    font-family: $ff-clinton;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-decoration: none;
                    color: rgb(128, 128, 128);
                    @include vw($padding: 25 30 20);
                    box-shadow: 0 2px 10px 0 rgba(5, 16, 44, .27);
                    background: #fff;
                    transition: color 0.4s ease, background 0.4s ease;

                    &:hover {
                        background: rgba(0, 0, 0, 1);
                        color: #fff;
                    }
                }
            }

            &--member {
                .member {
                    &__input {
                        display: none;

                        &:checked~label {
                            &:before {
                                // background: #ffe000;
                            }

                            &:after {
                                opacity: 1;
                                background: $brand;
                            }
                        }

                    }

                    &__item {
                        position: relative;
                        @include vw($margin-bottom: 10);
                    }

                    &__label {
                        display: block;
                        position: relative;
                        cursor: pointer;
                        font-weight: 600;
                        @include vw($padding: 0 0 0 33,
                            $margin-bottom: 0,
                            $font-size: 18);
                        font-family: $ff-clinton;
                        line-height: 1.4;

                        &:before {
                            content: '';
                            position: absolute;
                            border-radius: 50%;
                            background: #fff;
                            background: rgb(222, 214, 214);
                            @include vw($top: 0,
                                $left: 1,
                                $width: 24,
                                $height: 24);
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            border-radius: 50%;
                            background: rgb(222, 214, 214);
                            opacity: 0;
                            transition: all .2s;
                            @include vw($top: 4,
                                $left: 5,
                                $width: 16,
                                $height: 16);
                        }
                    }
                }
            }
        }

        &__step1 { 
            
            // overflow: scroll; 
            // @include vw($height: 788); 
            &--part1 {
                z-index: 10;
                opacity: 1;
                visibility: visible;
                ;

            }

            .order__wrap {
                @include vw($margin-top: 135);
                display: flex;
                justify-content: center;
            }

            .order__item--indv {
                @include vw($flex-basis: 185);

                .order__item--img {
                    @include vw($margin: 17 auto 0,
                        $width: 90,
                        $height: 179);
                    position: relative;

                    img {
                        width: 100%;
                        position: absolute;
                        display: block;
                        z-index: 1;
                    }
                }
            }

            .order__item--or {
                @include vw($flex-basis: 140);
                display: flex;
                align-items: flex-end;
                justify-content: center;
                text-align: center;
                @include vw($font-size: 18,
                    $margin-bottom: 0,
                    $margin-top: 0,
                    $padding-bottom: 20);
                line-height: 1.4;
                font-family: $ff-clinton;
                font-weight: bold;
                text-transform: uppercase;
                text-decoration: none;
                color: rgb(128, 128, 128);
            }

            .order__item--pack {
                @include vw($flex-basis: 260);
            }
        }

        /** step 2 **/
        &__step2 {
            z-index: 2;
            // opacity: 0;
            // visibility: hidden;;
            position: absolute;
            width: 100%;
            height: 100%;

            .order__step1 {

                // opacity: 0;
                // visibility: hidden;
                .order__item--img {
                    img {
                        &:first-child {
                            opacity: 1;
                            visibility: visible;
                        }

                        &:nth-child(2) {
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
                }

                .order__btn {}
            }

            &--part2 {
                background: #fff;
                // @include vw($padding: 72 0);
                position: absolute;
                width: 100%;
                // z-index: 0;
                height: 100%;
                opacity: 0;
                visibility: hidden;
                top: 0;
                left: 0;

                .order__layout--right {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            &--part3 {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                visibility: hidden;
                opacity: 0;
                z-index: 6;
            }
        }

        /** end step 2 **/
        /** mail **/
        &__mail {
            text-align: center;

            &--img {
                display: block;
                opacity: 0;
                visibility: hidden;
                @include vw($width: 320,
                    $margin: 170 auto 80,
                    $border-radius: 8);
            }

            &--input {
                max-width: 100%;
                display: block;
                background: #fff;
                width: 14%;
                @include vw( // $width: 600,
                $width: 190,
                    $margin: 0 auto,
                    // $height: 80,
                    $border-radius: 8,
                    $border: 2 solid #868686,
                    $margin-bottom: 22,
                    $padding: 25 20 18,
                    $font-size: 16);
                line-height: 1.6;
                font-family: $ff-got;
                text-align: center;

                &::-webkit-input-placeholder {
                    color: #868686
                }

                &::-moz-placeholder {
                    color: #868686
                }

                &:-moz-placeholder {
                    color: #868686
                }

                &:-ms-input-placeholder {
                    color: #868686
                }
            }

            button {
                opacity: 0;
                visibility: hidden;
                display: inline-block;
                text-align: center;
                @include vw($border: 2 solid #868686,
                    $border-radius: 8,
                    $font-size: 18,
                    $margin-bottom: 0,
                    $margin-top: 0);
                font-family: $ff-clinton;
                font-weight: bold;
                text-transform: uppercase;
                text-decoration: none;
                color: rgb(128, 128, 128);
                @include vw($padding: 25 30 20);
                box-shadow: 0 2px 10px 0 rgba(5, 16, 44, .27);
                background: #fff;
                max-width: 100%;

            }
        }

        /** step 3 **/
        &__step3 {
            z-index: 2;
            // opacity: 0;
            // visibility: hidden;;
            position: absolute;
            width: 100%;
            height: 100%;

            .order__step3--part1 {

                // opacity: 0;
                // visibility: hidden;
                .order__item--pack {
                    .order__item--img {
                        position: relative;
                        @include vw($height: 195);

                        img {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;

                            &:first-child {
                                opacity: 1;
                                visibility: visible;


                            }

                            &:nth-child(2) {
                                opacity: 0;
                                visibility: hidden;
                            }
                        }
                    }
                }


                .order__btn {}
            }

            &--part2 {
                background: #fff;
                // @include vw($padding: 72 0);
                position: absolute;
                width: 100%;
                // z-index: 0;
                height: 100%;
                opacity: 0;
                visibility: hidden;
                top: 0;
                left: 0;

                .order__layout--center {
                    transform: translateX(48%);
                }

                .order__layout--imgs {
                    @include vw($width: 312,
                        // $margin: 170 auto 9,
                        $margin: 98 auto 9,
                        $height: 223);

                    img {
                        display: block;
                    }
                }

                .order__layout--name {
                    opacity: 1;
                    visibility: visible;
                    z-index: 9;
                    position: relative;
                    text-align: center;

                    &>span {
                        // opacity: 0;
                        // visibility: hidden;

                        transform: translateX(-100%);
                        display: inline-block;

                        span {

                            transform: translateX(200%);
                            display: inline-block;
                        }

                    }

                }

                .order__layout--right {
                    opacity: 0;
                    visibility: hidden;
                    @include vw($padding: 85 15 0 43);
                }

                .order__layout--list {
                    @include vw($margin-bottom: 40);

                    li {
                        position: relative;
                        @include vw($padding: 0 0 14 33,
                            $font-size: 20);
                        font-family: $ff-clinton;
                        line-height: 1.4;

                        &:before {
                            content: '';
                            position: absolute;
                            display: block;
                            border-radius: 50%;
                            @include vw($width: 24,
                                $height: 24,
                                $top: -1,
                                $left: 0);
                        }

                        &.red {
                            &:before {
                                background: #E96F73;
                            }
                        }

                        &.yellow {
                            &:before {
                                background: #FFF020;
                            }
                        }

                        &.green {
                            &:before {
                                background: #41BAB9;
                            }
                        }
                    }
                }

                .order__layout--price {
                    .old {
                        position: relative;
                        display: inline-block;
                        @include vw($margin-right: 15);

                        &:after {
                            content: '';
                            width: 100%;
                            @include vw($height: 3);
                            background: #41BAB9;
                            top: 50%;
                            left: 0;
                            position: absolute;
                            transform: translateY(-50%);
                        }
                    }

                    .new {
                        color: #E96F73;
                    }
                }
                .order__layout--descr{
                    width: 100%;
                }
                .member__label.disabled {
                    opacity: 0.4;
                    cursor: not-allowed;
                }

            }

        }
    }
}