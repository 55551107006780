$text: #333;
$brand: rgb(255,255,51); 

/*
==============================
Fonts
==============================
*/
 
$ff: "MyriadPro", sans-serif;
$ff-din: "Din", sans-serif;
$ff-din-bold: "Din-bold", sans-serif;
$ff-got: "Gotham", sans-serif;
$ff-clinton: "Clinton", sans-serif;